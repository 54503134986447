.generate-invoice-option {
  img {
      width: 25px;
  }
  &:hover:not(.disabled){
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
    cursor: pointer;
  }
  font-size: 1.1rem;
  padding: 5px;
  min-width: 250px;
  &.disabled,  {
    .option-name {
      filter: grayscale(0.9);
      opacity: 0.5;
    }
    cursor: not-allowed;
  }
}

.clear-z-index {
  &.btn{
    z-index: initial;
  }
}

.timesheets {
  .modal-content{
    padding: 30px;
  }
  &.modal-dialog{
    max-width: 1140px;
  }
}

.timesheet-entries-table{
  .col {
    padding: 15px;
    font-size: 0.9rem;
    box-shadow: rgb(232, 232, 232) 0px -1px 0px 0px inset;
  }

  .entries{
    &.scrollable{
      height: 520px;
      overflow-y: scroll;
      overflow-x: scroll;
    }
    box-shadow: rgb(232, 232, 232) 0px -1px 0px 0px inset;
  }

  .cta-wrapper{
    padding: 20px;
  }



}


.invoice-items{
  .table td{
    padding: 1rem 0.5rem;
  }
}

.more-invoice-options{
  display: none;

  &.show{
    display: flex;
  }
}
