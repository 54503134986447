body {
    margin: 0;
    font-size: 2rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-select .react-select__control--is-focused.react-select__control--menu-is-open {
    border-color: red;
    box-shadow: 0 0 10px 1px pink;
}

{
    cursor: pointer
;
}

.unbounded-width .popover {
    max-width: initial;
}

.btn {
    transition: all 1s ease;
}

.oauth-status-circle {
    width: 140px;
    height: 140px;
    font-size: 70px;
    font-weight: 200;
    border: 6px solid;
    border-radius: 100px;
    background-color: #dfe3fa;
    animation: beat 0.5s infinite alternate

}

.oauth-status-circle.success {
    background-color: #d5f5e7;
}

.oauth-status-circle.expired {
    background-color: #fee0d9;
}

.delete-invoice-item:hover {
    transform: translateY(-2px);
    transition: transform 0.3s;
}

.timesheet-option {
    padding: 12px;
    font-size: 1rem;
    transition: font-size 0.2s ease-in-out;
}

.timesheet-option:hover {
    background-color: var(--lighter);
    cursor: pointer;
    font-size: 0.95rem;
}

.beat {
    animation: beat 0.5s infinite alternate;
}

@keyframes beat {
    to {
        transform: scale(0.98);
        opacity: 0.7;
    }
}

@keyframes beat {
    to {
        transform: scale(0.98);
        opacity: 0.7;
    }
}


